import styledSystem from '@emotion/styled';
import { css } from '@emotion/core';

import { space, width, fontSize, color, textAlign, justifyContent, borderColor, border, fontStyle, style } from 'styled-system';
import colors from '../../utils/colors';
import media from '../../utils/media';

export const Divider1 = styledSystem.hr`
  border-bottom-color: rgb(221, 221, 221);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-image-outset: 0;
  border-image-repeat: stretch stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-left-color: rgb(221, 221, 221);
  border-left-style: none;
  border-left-width: 0px;
  border-right-color: rgb(221, 221, 221);
  border-right-style: none;
  border-right-width: 0px;
  border-top-color: rgb(221, 221, 221);
  border-top-style: solid;
  border-top-width: 1px;
  box-sizing: content-box;
  color: rgb(128, 128, 128);
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-feature-settings: "liga", "kern";
  font-size: 14px;
  font-weight: 400;
  height: 5px;
  line-height: 22.4px;
  margin-bottom: 50px;
  margin-top: 50px;
  overflow: visible;
  overflow-x: visible;
  overflow-y: visible;
  text-align: left;
  text-rendering: optimizelegibility;
  -moz-osx-font-smoothing: grayscale;
  ${space} ${width} ${fontSize} ${color} ${textAlign} ${borderColor} ${fontStyle} ;
`;

export const Divider2 = styledSystem.hr`
  border-bottom-color: rgb(204, 204, 204);
  border-bottom-style: dashed;
  border-bottom-width: 1px;
  border-image-outset: 0;
  border-image-repeat: stretch stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-left-color: rgb(204, 204, 204);
  border-left-style: none;
  border-left-width: 0px;
  border-right-color: rgb(204, 204, 204);
  border-right-style: none;
  border-right-width: 0px;
  border-top-color: rgb(204, 204, 204);
  border-top-style: dashed;
  border-top-width: 1px;
  box-sizing: content-box;
  color: rgb(128, 128, 128);
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-feature-settings: "liga", "kern";
  font-size: 14px;
  font-weight: 400;
  height: 5px;
  line-height: 22.4px;
  margin-bottom: 50px;
  margin-top: 50px;
  overflow: visible;
  overflow-x: visible;
  overflow-y: visible;
  text-align: left;
  text-rendering: optimizelegibility;
  -moz-osx-font-smoothing: grayscale;
  ${space} ${width} ${fontSize} ${color} ${textAlign} ${borderColor} ${fontStyle} ;
`;

export const Divider3 = styledSystem.hr`
   border-bottom-color: rgb(128, 128, 128);
  border-bottom-style: none;
  border-bottom-width: 0px;
  border-image-outset: 0;
  border-image-repeat: stretch stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-left-color: rgb(128, 128, 128);
  border-left-style: none;
  border-left-width: 0px;
  border-right-color: rgb(128, 128, 128);
  border-right-style: none;
  border-right-width: 0px;
  border-top-color: rgba(0, 0, 0, 0.1);
  border-top-style: solid;
  border-top-width: 1px;
  box-sizing: content-box;
  color: rgb(128, 128, 128);
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-feature-settings: "liga", "kern";
  font-size: 14px;
  font-weight: 400;
  height: 0px;
  line-height: 22.4px;
  margin-bottom: 50px;
  margin-top: 50px;
  overflow: visible;
  overflow-x: visible;
  overflow-y: visible;
  text-align: left;
  text-rendering: optimizelegibility;
  -moz-osx-font-smoothing: grayscale;
  ${space} ${width} ${fontSize} ${color} ${textAlign} ${borderColor} ${fontStyle} ;
`;

export const Divider4 = styledSystem.div`
  margin-top: 5.71429rem;
  margin-bottom: 5.71429rem;
  border-color: #ddd !important;
  color: #fff;
  text-align: center;
  background-color: #72c02c;
  position: relative;
  border-top-style: solid;
  border-top-width: 1px;
  ${space} ${width} ${fontSize} ${color} ${textAlign} ${borderColor} ${fontStyle} ;
`;

export const Divider4IconStyle = css`
  color: #fff;
  position: absolute;
  top: -0.82857rem;
  font-size: 1.28571rem;
  text-align: center;
  font-style: normal;
  left: auto;
  background-color: ${colors.primary};
  right: auto;
  color: ${colors.tertiary};
  margin-left: -1.42857rem;
  & ::before {
    color: ${colors.tertiary};
    font-size: 1.28571rem;
    text-align: center;
    font-style: normal;
  }
  ${media.small`
    top: -1.52857rem;
    height: 50px;
    width: 50px;
  `};
`;

export const Divider5 = styledSystem.div`
  border-bottom-color: ${colors.secondary};
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left-color: ${colors.secondary};
  border-right-color: ${colors.secondary};
  border-top-color: ${colors.secondary};
  border-top-style: solid;
  border-top-width: 1px;
  box-sizing: border-box;
  color: ${colors.secondary};
  height: 5px;
  line-height: 22.4px;
  margin-left: auto !important;
  margin-right: auto !important;
  position: relative;
  text-align: center;
  text-rendering: optimizelegibility;
  width: 50%;
  -moz-osx-font-smoothing: grayscale;
  ${space} ${width} ${fontSize} ${color} ${textAlign} ${borderColor} ${fontStyle} ;
`;

export const Divider5IconStyle = css`
  color: ${colors.primary};
  background-color: ${colors.tertiary};
  left: auto;
  right: auto;
  margin-left: -1.42857rem;
  color: ${colors.primary} !important;
  background-color: ${colors.tertiary} !important;
  box-shadow: 0 0 0 15px ${colors.primary};
  position: absolute;
  top: -1.42857rem;
  width: 2.85714rem;
  height: 2.85714rem;
  font-size: 1.28571rem;
  line-height: 2.85714rem;
  text-align: center;
  font-style: normal;
  border-radius: 50% !important;
`;

export const HorizontalLine = styledSystem.div`
  border-bottom: solid;
  overflow: hidden;
  ${space} ${width} ${borderColor} ${border} ${textAlign};
`;
